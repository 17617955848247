.button {
  color: inherit;
  padding: 15px 20px;
  transition: all .3s ease-in-out;
  margin-top: 55px;
  line-height: 13px;
  @include mq($until: lg) {
    margin-top: 34px;
  }
  @include mq($until: md) {
    margin-top: 21px;
  }

  &--main{
    background-color: $color-main;
    border: 1px solid transparent;
    color: #fff;

    &:hover {
      background-color: #fff ;
      border: 1px solid $color-main;
      color:$color-main;
    }
  }

  &--reverse{
    border: 1px solid transparent;
    color: $color-main;
    background-color: #fff;
    
    &:hover {
      background-color:$color-extra;
      color: #fff;
    }
  }
}