.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    z-index: 9;
    min-height: 80px;

    background-color: $color-main;
    &--fixed {
        position: fixed;
        transition: background-color .3s ease-in-out;
        &.sticky{
          background-color: lemonchiffon;
        }
    }

    &--absolute {
        position: absolute;
    }

    &__logo{
      display: flex;
      align-items: center;
    }

    &__menuList{
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin: 0;
    }
    &__menuItem{
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 25px;
        padding: 0;
        &::before{
            display: none;
        }
    }
    &__menuLink{
        color:#fff;
        @include mq($until: md) {
            transition: all .3s;
        }

        &:active, &--active, &--open{
            color: grey;
        }

        &:hover {
            color: grey;
            @include mq($until: md) {
                color: white;
                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }
        }
    }
    &__logo {
        max-width: 30%;
        @include mq($until: md) {
            max-width: 40%;
        }
    }
}