.section{
  height: auto;
  min-height: 48vw;
  position: relative;
  display: flex;
  flex-direction: column;
  
  @include mq($until: lg) {
    height: auto;
  }
  &__wideo-wrapper{
    width: 100%;
    // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    height: auto;
    position: relative;
    .mask{
      position: absolute;
      width: 100%;
      height: 5vw;
      min-height: 34px;
      background: linear-gradient(180deg, rgba(255,255,255,0) 21%, rgba(255,255,255,1) 70%);
      bottom: 80px;
      z-index: 2;
      @include mq($until: lg) {
        bottom: 0px;
      }
    }
  }

  &__top-movie {
    position: relative;
    top: -80px;
    width: 100%;
    // z-index: -5;
    // width: calc(100% + 4px);
    // height:auto;
      @include mq($until: lg) {
        top: 0px;
      }
  }

}
.section1{
  height: auto;
  min-height: 48vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

.section-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  @include mq($until: lg) {
    font-size: 14px;
    flex-direction: column;
    justify-content: center;
  }

}
.section-text-image {
  width: 100%;
  background-color: rgb(255,255,255);
  display: flex;
  align-items: center;
  // padding-top: 5vw;
  padding-bottom: 35px;
  height: auto;


  p {
    line-height: 1.7;
    margin-bottom: 1rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 34vw;
    column-gap: 5vw;
    height: auto;
    min-height: 45vw;
    align-items: center;

    @include mq($until: lg) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 50px;
    }
    @include mq($until: md) {
      grid-template-columns: 1fr;
      grid-row-gap: 50px;

    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
    }

    &--left {
      grid-template-columns: 25vw 1fr;
    }
  }

  &__img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    min-height: 25vw;
    align-self: center;

    @include mq($until: md) {
      grid-row: 1/2;
      min-height: 34vw;
    }
    @include mq($until: sm) {
      display: none;
    }

    &--left {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 0 8vw 0 2vw;
  }

  .button {
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 55px;
  }
}

.section-full-screen-image {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;

  @include mq($until: lg) {
    flex-direction: column;
    justify-content: center;
  }
}

.contact-section {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  min-height: 50vh;
  height: auto;
  padding-bottom: 100px;
  padding-top: 100px;

  @include mq($until: lg) {
    padding-top: 30px;
    padding-bottom: 60px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.5fr;
  }
  @include mq($until: xs) {
    grid-template-rows: 1fr 1fr;
  }

  h4{
    color: $color-font-dark;
  }

  &__map-container {

    @include mq($until: lg) {
      margin-top: 50px;
    }
    @include mq($until: xs) {
      padding: 5px;
    }

    // &--border {
    //   border: 1px solid $color-main;
    //   padding: 30px;
    // }
  }

  &__social-media {
    display: flex;
    align-items: center;
  }

  .social-media__link {
    display: inline-block;
    margin-left: 10px;
    .svg-icon{
      fill: $color-font-dark;
      transition: fill .3s ease-in-out;
    }
    &:hover .svg-icon{
      fill: $color-main;
    }
  }

  &__contact {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    padding: 30px 50px 30px 0;

    @include mq($until: xl) {
      grid-row-gap: 20px;
    }

    @include mq($until: lg) {
      grid-column-gap: 15px;
      grid-template-columns: repeat(2, min-content 1fr);
    }

    @include mq($until: sm) {
      grid-template-columns:  min-content 1fr;
    }
  }

  &__text {
    line-height: 1.8;

    &--email {
      display: flex;
      align-items: center;
      @include mq($until: lg) {
        grid-column: 4/5;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
    &--phone{
      display: flex;
      align-items: center;
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }

  &__icon {
    width: 26px;

    img {
      width: 100%;
    }

    &--email {
      @include mq($until: lg) {
        grid-column: 3/4;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }
}

.section__background {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;

    @include mq($until: md) {
        margin-bottom: auto;
    }
    // @include mq($until: xs) {
    //     filter: brightness(70%);
    // }
  @include mq($until: lg) {
    position: static;
    height: 55vw;
    width: 100%;
    background-position: center center;
  }
  @include mq($until: xs) {
    // height: 75vw;
    height: auto;
    // min-height:340px;
  }
  &--other-size{
    height: auto;
    // min-height: unset;
  }
}

.svg-icon{
  width: 28px;
  fill: $color-main;
  display: flex;
  align-items: flex-start;
}
.logos{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5vw;
    .line{
      width: 100%;
      height: 1px;
      background-color: $color-extra;
      margin-bottom: 55px;
      @include mq($until: sm) {
        margin-bottom: 30px;
      }
    }
    .wrapper{
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .item{
        justify-content: space-between;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: auto;
        width: 10%;
        min-width: 60px;
        height: 65px;
        margin: 8px 13px;

      }

        .logo1{
          background-image:url('/storage/app/media/logos/fag.jpg');
        }
        .logo2{
          background-image:url('/storage/app/media/logos/kf.jpg');
        }
        .logo3{
          background-image:url('/storage/app/media/logos/koyo.jpg');
        }
        .logo4{
          background-image:url('/storage/app/media/logos/nadela.jpg');
        }
        .logo5{
          background-image:url('/storage/app/media/logos/skf.jpg');
        }
        .logo6{
          background-image:url('/storage/app/media/logos/snr.jpg');
        }  
    }
}


